.navbar {
    width: 100%;
    height: 110px;
    top: 0;
    left: 0;
    background-color: black;
    border-bottom: 2px solid #eee;
}

.navbar .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}

.navbar .nav-menu {
    display: flex;
}

.navbar li {
    padding: 1rem;
}

.hamburger {
    display: none;
    color: white;
}

.icon {
    font-size: 1.5rem;
}

.navBarLogo {
    height: 6rem;
    margin-top: -0.9rem;
    object-fit: scale-down;
}

@media screen and (max-width: 940px) {
    .hamburger {
        display: block;
    }
    .navbar .nav-menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        top: 90px;
        right: 100%;
        height: 350%;
        width: 100%;
        background-color: black;
        transition: 0.4s ease-in-out;
        z-index: 10;
        
    }
    .navbar .active {
        right: 0;
    }

    .navbar .nav-menu li a {
        font-size: 2rem;
    }
}